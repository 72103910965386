import { DialogService }                       from 'aurelia-dialog';
import { computedFrom, containerless, inject } from 'aurelia-framework';
import { BindingSignaler }                     from 'aurelia-templating-resources';
import { CommentsListingModal }                from 'modules/core/custom-components/comments/listing-modal';
import { AdditionalInfoListingModal }          from 'modules/core/listing-modals/additional-info-listing-modal';
import { Datatable }                           from 'resources/elements/html-elements/datatable';
import { AppContainer }                        from 'resources/services/app-container';
import { SweetAlert }                          from 'resources/services/sweet-alert';

@containerless
@inject(AppContainer, DialogService, SweetAlert, BindingSignaler)
export class MultilevelDatatable extends Datatable {

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param swalService
     * @param bindingSignaler
     */
    constructor(appContainer, dialogService, swalService, bindingSignaler) {
        super(appContainer, dialogService, swalService, bindingSignaler);
    }

    bind(bindingContext) {
        super.bind(bindingContext);
    }

    attached() {
        this.computeSpansForAllColumns();

        super.attached();
    }

    /**
     * Recursively compute colSpan and rowSpan for each column,
     * based on children’s visibility and colSpan.
     */
    computeSpansForAllColumns() {
        this.schema.columns.forEach(col => {
            this.computeSpan(col);
            this.computeStyling(col);
        });
    }

    computeStyling(col) {
        const level = this.getLevel(this.schema.columns, col);

        if (level === 1) {
            col.style += '; background-color: #E3EAF2';
        } else if (level === 2) {
            col.style += '; background-color: #D1DCE6';
        } else if (level === 3) {
            col.style += '; background-color: #BBC8D9';
        } else if (level === 4) {
            col.style += '; background-color: #A7B5CC';
        } else if (level === 5) {
            col.style += '; background-color: #91A2BF';
        } else if (level === 6) {
            col.style += '; background-color: #7C90B3; color: white';
        } else if (level === 7) {
            col.style += '; background-color: #667CA6; color: white';
        } else if (level === 8) {
            col.style += '; background-color: #51699A; color: white';
        } else if (level >= 9) {
            col.style += '; background-color: #3B568D; color: white';
        }

        if (col.children) {
            col.children.forEach(child => {
                this.computeStyling(child);
            });
        }
    }

    getLevel(columns, col) {
        return this.getLevelRecursive(columns, col, 1);
    }

    getLevelRecursive(columns, col, level) {
        for (const c of columns) {
            if (c === col) {
                return level;
            }
            if (c.children) {
                const found = this.getLevelRecursive(c.children, col, level + 1);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    /**
     * Returns total leaf count under `col` if visible.
     */
    computeSpan(col) {
        if (col.visible === false) {
            col.colSpan = 0;
            col.rowSpan = 0;
            return 0;
        }

        if (!col.children || col.children.length === 0) {
            col.colSpan = 1;
            col.rowSpan = this.getMaxDepth(this.schema.columns);
            return 1;
        }

        let total = 0;
        for (const child of col.children) {
            total += this.computeSpan(child);
        }
        col.colSpan = total;
        col.rowSpan = 1;
        return total;
    }

    /**
     * Find the deepest nesting level.
     */
    getMaxDepth(columns, currentDepth = 1) {
        let max = currentDepth;
        for (const col of columns) {
            if (col.visible !== false && col.children && col.children.length > 0) {
                const depth = this.getMaxDepth(col.children, currentDepth + 1);
                if (depth > max) {
                    max = depth;
                }
            }
        }
        return max;
    }

    /**
     * Recursively build rows for the table header.
     * Each row is an array of columns at that depth.
     */
    buildHeaderRows(columns, level, rows) {
        if (!rows[level]) {
            rows[level] = [];
        }
        for (const col of columns) {
            if (col.visible === false || col.colSpan === 0) {
                continue;
            }
            rows[level].push(col);
            if (col.children && col.children.length > 0) {
                this.buildHeaderRows(col.children, level + 1, rows);
            }
        }
    }

    @computedFrom('schema.columns', 'schema.columns.length', 'selectedColumns.length')
    get headerRows() {
        this.schema.columns.forEach(col => {
            if (col.children && col.children.length) {
                col.display = col.children.some(child => child.display !== false && child.hidden !== false);
            }
        });

        this.computeSpansForAllColumns();

        const depth = this.getMaxDepth(this.schema.columns);

        const rows = [];
        this.buildHeaderRows(this.schema.columns, 0, rows);

        rows.forEach((row, level) => {
            row.forEach(col => {
                if (!col.children || !col.children.length) {
                    col.rowSpan = depth - level;
                }
            });
        });

        return rows;
    }

    /**
     * Recursively flatten columns to get only leaves for <tbody>.
     */
    @computedFrom('schema.columns')
    get leafColumns() {
        const leaves = [];
        const stack  = [...this.schema.columns];
        while (stack.length) {
            const col = stack.pop();
            if (!col) continue;

            if (col.visible === false || col.colSpan === 0) {
            } else if (col.children && col.children.length) {
                col.children.forEach(child => stack.push(child));
            } else {
                leaves.unshift(col);
            }
        }
        return leaves;
    }

    /**
     * Helper to check if a column is leaf (no children).
     */
    isLeaf(col) {
        return !col.children || col.children.length === 0;
    }

    onHeaderClick(col, event) {
        if (this.isLeaf(col) && col.sortable !== false) {
            this.doSort(col, event);
        }
    }

    doSort(col, event) {
        // TODO: Implement sorting logic here
        //      * TENTAR REPLICAR O FUNCIONAMENTO DA DATATABLE NORMAL
    }

    /**
     * Toggle a column's visibility by data key (if you have a UI or checkboxes).
     */
    toggleColumnVisibility(dataKey) {
        const col = this.findColumnByData(this.schema.columns, dataKey);
        if (col) {
            col.visible = !col.visible;
        }
    }

    findColumnByData(columns, dataKey) {
        for (const c of columns) {
            if (c.data === dataKey) {
                return c;
            }
            if (c.children) {
                const found = this.findColumnByData(c.children, dataKey);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    @computedFrom('leafColumns')
    get dataColumns() {
        return this.leafColumns;
    }

    @computedFrom('schema', 'leafColumns')
    get totalColumns() {
        let totalColumns = this.leafColumns.length;

        if (this.schema.edit || this.schema.destroy || this.schema.actions) {
            totalColumns++;
        }

        return ++totalColumns;
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Opens the additional information modal
     */
    openAdditionalInformationModal(row) {
        this.openModal({ relatableId: row.test_header_id }, AdditionalInfoListingModal);
    }

    /**
     * Opens the comments modal
     */
    openCommentsModal(row) {
        this.openModal({ relatableId: row.test_header_id, forceFilterByLot: false }, CommentsListingModal);
    }
}
