import { bindable, bindingMode, containerless, inject } from 'aurelia-framework';
import { AppContainer }                                 from 'resources/services/app-container';
import { WarningAlertMessage }                          from 'resources/services/warning-alert-message';

@containerless()
@inject(AppContainer)
export class TabsNavElement {

    @bindable active                                              = false;
    @bindable target                                              = null;
    @bindable icon                                                = null;
    @bindable label                                               = null;
    @bindable disabled                                            = false;
    @bindable minified                                            = true;
    @bindable warningMessage                                      = null;
    @bindable clickCustomTrigger                                  = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) warning = {};

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Returns a new warning warning message
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {WarningAlertMessage}
     */
    warningAlertMessage(message, details, scrollTop, css, icon) {
        return new WarningAlertMessage(
            message,
            details,
            scrollTop,
            css,
            icon,
        );
    }

    /**
     * Handles tab click
     */
    handleTabClick() {
        if (this.disabled && this.warning && this.warningMessage) {
            this.warning = this.warningAlertMessage(this.appContainer.i18n.tr(this.warningMessage));
        }
        // Custom trigger
        if (this.clickCustomTrigger instanceof Function) {
            this.clickCustomTrigger();
        }
    }
}
